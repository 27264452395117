/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";

.login {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    width: 350px;
    margin-bottom: 0.75rem;
  }

  .button-row {
    display: flex;
    justify-content: space-between;
  }
}

.clearbutton {
  border-radius: 1rem;
  margin-left: 0.5rem;
  color: black;
  background: transparent;
  border: none;
  width: 2rem;
  height: 2rem;
  font-weight: 900;
}

.clearbutton:hover {
  cursor: pointer;
  color: var(--bs-red);
}

.table-wrapper {
  display: block;
  margin: 1rem 0;
  background-color: #fff;

  .table {
    width: 100%;
  }
}

.clickable {
  cursor: pointer;
}

.row {
  display: flex;
}

.title {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 1em;
  box-shadow: inset 0px -1px 0px #dddddd;
}

.sectiontitle {
  display: flex;
  justify-content: space-between;
  //margin: 3rem 0 2rem 0;
  margin: 0 0 1rem 0;

  h2 {
    display: block;
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.requiredFieldWrapper {
  border: 0.01rem solid rgba(0, 0, 0, 0.10);
  border-radius: 0.5rem;
  padding: 1rem;
}

.extraInfo {
  position: relative;
  cursor: pointer;
}

.extraInfo:hover::after {
  content: "Vul ten minste één van de velden Festival, Contract of Event in.";
  position: absolute;
  background: white;
  border: solid 0.1rem gray;
  color: gray;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  bottom: 100%;
  left: 50%;
  z-index: 10;
  opacity: 1;
  font-size: 0.8rem;
  visibility: visible;
  font-family: sans-serif;
  font-weight: lighter;
}

.extraInfo::after {
  content: "";
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0.2s;
}


.tab-form {
  max-width: 500px;
}

.table-menu-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.tiles {
  display: flex;
  justify-content: space-around;
}

.table {
  thead {
    th {
      text-transform: uppercase;
      font-size: 0.825rem;
    }
  }
}

//@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&family=Poppins:wght@400,700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

:root {
  --bs-blue: #006ac0;

  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #ea3814;
  --bs-orange: #ff6a00;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #00a2ff;
  --bs-warning: #ffc107;
  --bs-danger: #ea3814;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;

  --bs-font-sans-serif: "Inter", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0));
}

@mixin downXl {
  @media (max-width: 1119px) {
    @content;
  }
}

@mixin downLg {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin downMd {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin downSm {
  @media (max-width: 575px) {
    @content;
  }
}

.btn {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-weight: 500;
}

$border: #eee;

html {
  font-size: 13px !important;
  overflow: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

.app {
  display: flex;
  height: 100vh;
  width: 100%;

  &__session {
    width: 100%;
    height: 100%;
    display: flex;

    &__image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }

    &__content {
      max-width: 440px;
      border-right: 1px solid $border;
      width: 100%;
      padding: 2rem;
      overflow-y: auto;

      header {
        margin-bottom: 2rem;

        img {
          max-height: 2rem;
          max-width: 10rem;
        }
      }

      h1 {
        font-size: 1.5rem;
      }
    }
  }

  &__sidebar {
    max-width: 220px;
    border-right: 1px solid $border;
    width: 100%;
    background-color: #fff;
    padding: 0 1.5rem 1.5rem 1.5rem;
    overflow-y: auto;
    height: 100%;

    @include downMd {
      position: fixed;
      left: 0;
      top: 0;
    }

    &__divider {
      margin: 1rem 0;
      border-top: 1px solid $border;
    }

    header {
      margin-bottom: 1rem;
      border-bottom: 1px solid $border;
      height: 5rem;
      align-items: center;
      display: flex;

      img {
        max-height: 4rem;
        max-width: 10rem;
      }
    }

    nav {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 0.95rem;

        ul {
          padding-left: 1.5rem;
        }

        li {
          a {
            padding: 0.75rem 0;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: var(--bs-secondary);
            font-weight: 600;

            &:hover {
              color: #000;
            }

            i {
              font-size: 1rem;
              margin-right: 0.5rem;
              color: #ccc;
            }

            &.router-link-active,
            &.is-active,
            &.active {
              color: #000;

              i {
                color: var(--bs-primary);
              }
            }
          }
        }
      }
    }

    &__profile {
      a {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start !important;

        small {
          opacity: 0.5;
        }
      }
    }
  }

  &__main {
    padding: 0 2rem 2rem 2rem;
    width: 100%;
    background-color: #fbfdff;
    overflow-y: auto;

    &__title {
      display: flex;
      align-items: center;
      height: 5rem;
      border-bottom: 1px solid $border;
      margin-bottom: 2rem;
      justify-content: space-between;

      .breadcrumb {
        margin-bottom: 0.5rem !important;
        font-size: 0.825rem;
      }

      h1 {
        font-size: 1.125rem;
        margin: 0;
      }
    }

    &__content {}
  }
}

.green {
  color: green;
}

.red {
  color: red;
}

.grey {
  color: gray;
}

.app__sidebar nav ul li a.no-link {
  cursor: initial;

  &:hover {
    color: var(--bs-secondary);
  }
}
